// React & Utilities
import type { FC } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Container, Tabs } from '@mui/material';

// Layout Components
import { PageTitle } from '@/components/_shared/page-elements/page-title';

// Page Content, Sections & Components
import { Box } from '@mui/system';
import { ButtonDetailsType, HandleChange } from '@/lib/types/change-page-tabs';
import TimeframeSelector from '../timeframe-selector';
import { PageActiveTabButtons } from '../page-active-tab-buttons';

// Types

interface PageHeaderMobileProps {
  title: string;
  timeframeSelector?: boolean;
  buttons?: boolean;
  createCampaignStepsTabs?: boolean;
  handleChange?: HandleChange;
  buttonDetails?: ButtonDetailsType[] | undefined;
  pageTabs?: {
    id: number;
    value: number;
    label: string;
  } | undefined;
}
export const PageHeaderMobile: FC<React.PropsWithChildren<PageHeaderMobileProps>> = props => {
  const {
    title,
    timeframeSelector,
    buttons,
    buttonDetails,
    pageTabs,
    handleChange
  } = props;
  return <>
      <div className="block md:hidden">
        <Container maxWidth="xl" sx={{
        px: 3,
        py: 2
      }} data-sentry-element="Container" data-sentry-source-file="page-header-mobile.tsx">
          <div className="flex flex-col items-center ">
            <div className="flex flex-row space-x-5 pb-5">
              <PageTitle title={title} data-sentry-element="PageTitle" data-sentry-source-file="page-header-mobile.tsx" />
              {timeframeSelector && <TimeframeSelector />}
              {/* This is a spacer to correct the spacing for pages where the date range selector isn't active */}
              {!timeframeSelector && <Box sx={{
              width: '100%'
            }}>
                  <Tabs></Tabs>
                </Box>}
            </div>
            <div className="pb-5">
              {buttons === true && <>
                  <PageActiveTabButtons pageTabs={pageTabs} buttonDetails={buttonDetails} handleChange={handleChange} />
                </>}
            </div>
            <div className=""></div>
          </div>
        </Container>
      </div>
    </>;
};
PageHeaderMobile.propTypes = {
  title: PropTypes.string.isRequired
};