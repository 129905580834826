import { FunctionComponent, ReactNode } from 'react';
interface StandardizedElementHeightLayoutProps {
  statCards: ReactNode;
  charts: ReactNode;
}
const StandardizedElementHeightLayout: FunctionComponent<StandardizedElementHeightLayoutProps> = ({
  statCards,
  charts
}) => {
  return <div style={{
    minHeight: 'calc(100vh - 120px)'
  }} data-sentry-component="StandardizedElementHeightLayout" data-sentry-source-file="standardized-element-height-layout.tsx">
      <div>{statCards}</div>
      <div style={{
      height: 'calc(100vh - 265px)'
    }}>
        {charts}
      </div>
    </div>;
};
export default StandardizedElementHeightLayout;