import { ButtonDetailsType, HandleChange } from '@/lib/types/change-page-tabs';
import PropTypes from 'prop-types';
import { PageHeaderDesktop } from './page-header-desktop';
import type { FC } from 'react';
import { PageHeaderMobile } from '@/components/_shared/page-elements/page-header/page-header-mobile';
interface PageHeaderProps {
  title: string;
  timeframeSelector?: boolean;
  buttons?: boolean;
  createCampaignStepsTabs?: boolean;
  handleChange?: HandleChange;
  buttonDetails?: ButtonDetailsType[] | undefined;
  importProspectsButton?: boolean;
  pageTabs?: {
    id: number;
    value: number;
    label: string;
  } | undefined;
}
export const PageHeader: FC<React.PropsWithChildren<PageHeaderProps>> = props => {
  const {
    title,
    timeframeSelector,
    buttons,
    buttonDetails,
    pageTabs,
    handleChange,
    importProspectsButton
  } = props;
  return <>
      {/* Desktop View */}
      <PageHeaderDesktop {...props} buttons={buttons} buttonDetails={buttonDetails} importProspectsButton={importProspectsButton} data-sentry-element="PageHeaderDesktop" data-sentry-source-file="page-header.tsx" />

      <PageHeaderMobile {...props} data-sentry-element="PageHeaderMobile" data-sentry-source-file="page-header.tsx" />
    </>;
};
PageHeader.propTypes = {
  title: PropTypes.string.isRequired
};