import type { FC } from 'react';
import Typography from '@mui/material/Typography';
import ShowStatDeltaComponent from './show-stat-delta-component';
import { Str } from '@supercharge/strings/dist';

// Types
interface SingleStatCardProps {
  statCard?: string;
  asCurrency?: boolean;
  asPercentage?: boolean;
  showDays?: boolean;
  showDelta?: boolean;
  showEvery?: boolean;
  showGender?: string;
  statAges?: string;
  statDelta?: number | null;
  statDescription?: string;
  statLabel?: string;
  statPrevious?: number | string;
  statValue?: number | string;
  timeFrameSetting?: string;
  statPreviousShow?: boolean;
}
export const SingleStatCard: FC<React.PropsWithChildren<SingleStatCardProps>> = (props: SingleStatCardProps) => {
  const {
    statCard,
    asCurrency,
    asPercentage,
    showDays,
    showDelta,
    showEvery,
    showGender,
    statAges,
    statDelta,
    statDescription,
    statLabel,
    statPrevious,
    statValue,
    timeFrameSetting,
    statPreviousShow
  } = props;
  const statCardPadding = !statPreviousShow && !statDescription ? 'py-5' : 'py-3';
  return <div className={`h-28 px-9 ${statCardPadding}`} data-sentry-component="SingleStatCard" data-sentry-source-file="single-stat-card.tsx">
      <div className="">
        <div className="flex justify-start">
          <div className="truncate font-proximaBold text-lg text-[#2E3145]">
            {statLabel}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div>
            <Typography color={'primary'} display={'flex'} component={'div'} alignItems={'flex-end'} data-sentry-element="Typography" data-sentry-source-file="single-stat-card.tsx">
              <span className="truncate font-proximaExtraBold lg:text-xl xl:text-2xl 2xl:text-3xl">
                {showGender && showGender + ' '}
                {asCurrency === true && '$'}
                {showEvery === true && 'Every '}
                {statValue?.toLocaleString()}
                {statAges && statAges}
                {showDays === true && ' Days'}
                {asPercentage === true && '%'}
              </span>
            </Typography>
          </div>
          <div className="ml-8">
            {showDelta && <ShowStatDeltaComponent statDelta={statDelta} />}
          </div>
        </div>
        <div className="flex justify-start">
          <Typography color={'text.secondary'} component={'div'} data-sentry-element="Typography" data-sentry-source-file="single-stat-card.tsx">
            <span className="text-sm">
              {statPreviousShow && <>
                  from {asCurrency === true && '$'}
                  {statPrevious?.toLocaleString()}
                </>}
              {statDescription && Str(statDescription).limit(30, '...').get()}
            </span>
          </Typography>
        </div>
      </div>
    </div>;
};
export default SingleStatCard;