import { useAppDispatch, useAppSelector } from '@/lib/state/hooks';
import { changeTimeframe } from '@/lib/state/slices/timeframe-slice';
import { Tab, Tabs } from '@mui/material';
import * as React from 'react';
type TimeframeOption = {
  id: number;
  value: number;
  label: string;
};
const timeframeOptions: TimeframeOption[] = [{
  id: 0,
  value: 1,
  label: 'Last 7 Days'
}, {
  id: 1,
  value: 2,
  label: 'Last 4 Weeks'
}, {
  id: 2,
  value: 3,
  label: 'Last 6 Months'
}];
function TimeframeSelector() {
  const dispatch = useAppDispatch();
  const timeframe = useAppSelector(state => state.timeframe);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(changeTimeframe(newValue));
  };
  const renderTabLabel = (option: TimeframeOption) => {
    const isActive = option.value === timeframe.value;
    const fontFamily = isActive ? 'proxima-nova-bold' : 'proxima-nova-regular';
    const marginLeft = '1.5rem';
    const paddingBottom = '0.75rem';
    return <Tab key={option.value} value={option.value} label={option.label} aria-label={`${option.label} Selected`} sx={{
      fontFamily,
      marginLeft,
      paddingBottom
    }} data-sentry-element="Tab" data-sentry-component="renderTabLabel" data-sentry-source-file="timeframe-selector.tsx" />;
  };
  return <div className=" text-[#65687B]" data-sentry-component="TimeframeSelector" data-sentry-source-file="timeframe-selector.tsx">
      <Tabs TabIndicatorProps={{
      sx: {
        mb: 1
      }
    }} value={timeframe.value} onChange={handleChange} indicatorColor="primary" textColor="inherit" aria-label="Date Range Selector" data-sentry-element="Tabs" data-sentry-source-file="timeframe-selector.tsx">
        {timeframeOptions.map(option => renderTabLabel(option))}
      </Tabs>

      {/* Mobile View */}
      {/* <div className="block w-full ">
        <FormControl fullWidth>
          <Select
            size="small"
            value={timeframe.value}
            onChange={handleMUIChange}
            id="timeframe-selector-dropdown"
            sx={{ fontSize: '0.875rem' }}
          >
            {timeframeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
       </div> */}
    </div>;
}
export default TimeframeSelector;