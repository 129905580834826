import * as React from 'react';
import type { FC } from 'react';
interface SectionTitleDescriptionComponentProps {
  title: string;
  number?: number;
  description?: string;
  disabled?: boolean;
}
export const SectionTitleDescriptionComponent: FC<React.PropsWithChildren<SectionTitleDescriptionComponentProps>> = props => {
  const {
    title,
    description,
    number,
    disabled = false
  } = props;
  return <div className="flex items-center mt-4 w-max " data-sentry-component="SectionTitleDescriptionComponent" data-sentry-source-file="section-header.tsx">
      {number && <div className="flex items-center justify-center w-6 -mr-2 text-white bg-orange-500 rounded-full font-proximaSemiBold">
          {number}
        </div>}
      <div className="flex justify-start pl-4 mt-1">
        <div>
          <div className={`${disabled ? 'text-gray-300' : 'text-[#2E3145]'} `}>
            <span className="ml-1 text-base font-semibold leading-6 text-gray-900 ">
              {`${title}`}
            </span>
            <span className="ml-1 text-sm">{`${description || ''}`} </span>
          </div>
        </div>
      </div>
    </div>;
};