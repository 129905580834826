import React from 'react';
import { Button, Grid } from '@mui/material';
import { ButtonDetailsType, HandleChange } from '@/lib/types/change-page-tabs';
interface PageActiveTabButtonsProps {
  buttonDetails: ButtonDetailsType[] | undefined;
  pageTabs?: {
    id: number;
    value: number;
    label: string;
  } | undefined;
  handleChange?: HandleChange | undefined;
}
const getButtons = (buttonDetails: ButtonDetailsType[] | undefined, pageTabs: {
  id: number;
  value: number;
  label: string;
} | undefined, handleChange: HandleChange | undefined) => {
  // Return null if buttonDetails or handleChange is undefined
  if (!buttonDetails || !handleChange) {
    return null;
  }

  // Generate and return the buttons if buttonDetails and handleChange are defined
  return buttonDetails.map((option: ButtonDetailsType) => {
    const color = option.value === pageTabs?.value ? '#fff' : '#65687B';
    const backgroundColor = option.value === pageTabs?.value ? 'primary' : '#e9e9ec';
    const fontFamily = option.value === pageTabs?.value ? 'proxima-nova-bold' : 'proxima-nova-regular';
    return <Button size="small" key={option.value} startIcon={option.icon} value={option.value} variant="contained" onClick={event => handleChange(event, option.value)} sx={{
      backgroundColor: backgroundColor,
      color: color,
      fontFamily: fontFamily,
      mx: 0.75,
      ':hover': {
        color: '#fff'
      }
    }}>
        <p className="text-base">{option.name}</p>
      </Button>;
  });
};
export const PageActiveTabButtons: React.FC<PageActiveTabButtonsProps> = props => {
  const {
    buttonDetails,
    handleChange,
    pageTabs
  } = props;
  const buttons = getButtons(buttonDetails, pageTabs, handleChange);
  return <>
      <div className="lg:ml-8">
        <Grid alignItems="center" data-sentry-element="Grid" data-sentry-source-file="page-active-tab-buttons.tsx">{buttons}</Grid>
      </div>
    </>;
};