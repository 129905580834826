import type { FC } from 'react';
import PropTypes from 'prop-types';
interface PageTitleProps {
  title: string;
}
export const PageTitle: FC<React.PropsWithChildren<PageTitleProps>> = props => {
  const {
    title
  } = props;
  return <div className="whitespace-nowrap pl-4 font-proximaBold text-2xl text-[#2E3145] sm:col-span-2 md:col-span-12" data-sentry-component="PageTitle" data-sentry-source-file="page-title.tsx">
      {title}
    </div>;
};
PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};