import type { FC } from 'react';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import { Typography } from '@mui/material';
interface ShowStatDeltaComponentProps {
  statDelta?: number | null;
}
export const ShowStatDeltaComponent: FC<React.PropsWithChildren<ShowStatDeltaComponentProps>> = props => {
  const {
    statDelta
  } = props;
  return <div data-sentry-component="ShowStatDeltaComponent" data-sentry-source-file="show-stat-delta-component.tsx">
      {statDelta !== null && <Typography component={'span'} sx={{
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: 1,
      bgcolor: alpha(statDelta && statDelta < 0 ? '#F7C9D2' : '#D8FDED', 1),
      color: statDelta && statDelta < 0 ? '#A11730' : '#068850',
      paddingX: 1,
      paddingY: 0.5,
      borderRadius: 2
    }}>
          <Box component={'svg'} xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" viewBox="0 0 24 24" stroke="currentColor" marginRight={0.5}>
            {statDelta && statDelta < 0 ? <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 17l-4 4m0 0l-4-4m4 4V3" /> : <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7l4-4m0 0l4 4m-4-4v18" />}
          </Box>
          {statDelta?.toLocaleString()}%
        </Typography>}
    </div>;
};

// rewrite ShowStatDeltaComponent to work without errors in typescript

export default ShowStatDeltaComponent;